<template>
  <div id="seyvom__comingsoon">
    <Background />
    <div class="row">
      <div class="col-md-7 comingsoon__we">
        <div class="comingsoon__build_box">
          <div class="--comingsoong_build1--">
            <div data-depth="0.3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="v__build"
                width="752.46"
                height="1032"
                viewBox="0 0 902.46 1032"
              >
                <path
                  style="fill:#c54342;"
                  d="M1387.275,765.066l39.479-59.942L1187,547.2l-59.942-39.479-39.484,59.937L1008.609,687.54,969.13,747.482l59.942,39.479,119.879,78.963,39.479-59.942-119.879-78.963,78.963-119.879Z"
                  transform="translate(-524.295 -274.673)"
                />
                <path
                  style="fill:#ffc65a;"
                  d="M1348.722,527.883c-1.836,1.836,2.915,92.65,4.824,95.193s4.131,1.207,4.131,1.207,1.4,4.948,5.15,2.341c.5,2.1,2.336,3.576,3.5,2.341,0,0,.207,2.534,2.024,2.139s2.07-90.952,1.469-92.412-2.8-.349-2.8-.349-.211-2.846-2.02-3.263a1.69,1.69,0,0,0-2.2,1.106s-.67-2.451-2.171-3.13-2.616.918-2.616.918.62-2.639-1.047-3.048-2.162.542-2.162.542,0-2.7-1.037-2.915a1.577,1.577,0,0,0-1.469.239S1349.814,526.786,1348.722,527.883Z"
                  transform="translate(-729.427 -285.404)"
                />
                <path
                  style="fill:#262626;"
                  d="M237.245,2186.31c1.588,3.792,44.556-5.715,46.428-8.749s-17.015-42.251-17.015-42.251l-25.883,7.964S231.31,2172.164,237.245,2186.31Z"
                  transform="translate(-127.291 -1155.19)"
                />
                <path
                  style="fill:#262626;"
                  d="M449.005,2146.093c5.458,1.285,29.835-2.235,31.557-7.459s-19.586-32.764-19.586-32.764l-21.325,6.885S438.03,2143.5,449.005,2146.093Z"
                  transform="translate(-237.804 -1139.263)"
                />
                <path
                  style="fill:#353535;"
                  d="M85.1,1812.016a267.683,267.683,0,0,0,23.23,16.414,84.5,84.5,0,0,1,38.1,53.841c8.147,37.235,0,74.46,1.019,78.417,3.374,13.031,42.366-8.891,42.366-8.891l.679-83.47a104.948,104.948,0,0,0-7.895-30.152l-25.681-32.658c-5.1-24.327-47.686-7.436-52.786-31.727-3.493.734-6.972,1.437-10.374,2.1C76.565,1779.248,71.282,1801.266,85.1,1812.016Z"
                  transform="translate(-41.778 -959.61)"
                />
                <path
                  style="fill:#525354;"
                  d="M169.546,1747.41c-11.576,4.53-37.313,10.53-53.566,13.954,2.015,6.348,1.166,18.36,21.716,29.376,27.237,14.6,32.3,18.053,47.2,24.75,18.663,8.4,27.058,1.418,36.872,15.606,18.135,38.979,36.913,91.87,40.746,94.046,6.55,3.713,26.2-12.623,26.2-12.623s-11.113-42.563-25.893-92.861C250.836,1778.893,182.371,1779.811,169.546,1747.41Z"
                  transform="translate(-62.746 -945.338)"
                />
                <path
                  style="fill:#c67a60;"
                  d="M143.89,1303.967s7.344.105,8.5-15.868,12.852-22.188,17.791-18.213-3.53,13.454-.826,15.744-8.23,14.229-8.23,14.229S141.434,1310.072,143.89,1303.967Z"
                  transform="translate(-77.73 -686.37)"
                />
                <path
                  style="fill:#a57367;"
                  d="M185.764,1271.26s-13.311,7.762-11.1,13.059-2.171,16.657-2.171,16.657,6.371-9.882,7.762-12.568,1.813-2.235,4.131-1.579C186.113,1277.769,190.111,1273.408,185.764,1271.26Z"
                  transform="translate(-93.317 -687.744)"
                />
                <path
                  style="fill:#8d2b26;"
                  d="M90.338,1426.978s-13.839-1.6.578-34.334,27.15-52.478,27.15-52.478-11.627-21.289-27.472.693S42.5,1423.15,54.159,1441.7,47.995,1498.643,90.338,1426.978Z"
                  transform="translate(-27.956 -720.013)"
                />
                <path
                  style="fill:#edd3d6;"
                  d="M126.4,1708.93l19.875,23.116s-31.671,34.265-48.466,38.341-56.6,14.11-34.884-10.5S126.4,1708.93,126.4,1708.93Z"
                  transform="translate(-30.619 -924.521)"
                />
                <path
                  style="fill:#c67a60;"
                  d="M51.507,1299.176c14.688-1.441,32.062-10.474,30.51,7.936s-.326,31.313-4.774,36.06c-13.137,7.8-13.357,13.513-34.609,9.557C22.466,1322.076,45.971,1299.718,51.507,1299.176Z"
                  transform="translate(-18.721 -700.963)"
                />
                <path
                  style="fill:#a57367;"
                  d="M113.513,1391.14s-10,1.685-13.1,10.3C91.16,1407.141,113.229,1397.763,113.513,1391.14Z"
                  transform="translate(-53.1 -752.598)"
                />
                <path
                  style="fill:#c67a60;"
                  d="M21,1429.057c-.491,1.152.211,1.9,1.019,2.625,7.252,6.472,16.63,8.629,27.742,6.316,10.86-2.263,18.773-7.748,23.116-16.478.427-.858,5.476,1.542,4.98.734-6.61.867-16.767-3.374-19.278-8.684-5.866,2.3-11.9,1.478-17.851,2.084-3.048.308-3.608-2.52-5.43.17C34.075,1421.3,27.534,1427.57,21,1429.057Z"
                  transform="translate(-11.273 -764.733)"
                />
                <path
                  style="fill:#1c1c1c;"
                  d="M77.872,1310.344c-1.175.28-4.792,9.51-6.926,9.745-6.66.734-12.682,15.813-19.673,14.964-8.831-1.074-5.508-6.646-15.244-5.127-1.377.216-3.9-32.589-3.768-35.674s1.308-6.463-2.194-8.469c-1.891-1.083-1.271-3.213,1.207-4.985,2.368-1.68,5.049-2.667,7.844-4-1.377-1.377-3.365-.344-4.622-1.069-1.028-.588-2.3-.918-1.735-2.437.459-1.276,1.68-1.579,2.928-1.8,3.828-.693,7.509-.459,11.36-.459-.115-.574-.078-.872-.225-1.023-1.1-1.184-4.7-.661-3.635-3.043.95-2.125,3.805-1.441,5.669-1.538,9.5-.5,18.98-.808,26.3,4.007.918,1.207.578,2.635-.156,4.081C71.492,1280.426,86.3,1307.512,77.872,1310.344Z"
                  transform="translate(-15.669 -684.443)"
                />
                <path
                  style="fill:#262626;"
                  d="M121.8,1291.906c2.341-8.482-7.61-12.586-12.1-17.406a29.458,29.458,0,0,1-.849,3.126c-2.837,7.275-8.886,12.3-16.065,16.524,3.888,0,7.7-1.377,11.393-2.919,2.5-1.042,3.259-.372,3.539,1.377.459,3,1.327,5.926,3.521,7.95,1.657,1.528,1.923,3.213,1.611,5.379-.459,3.112-.633,6.206-.987,9.878,2.626-1.891,1.6-3.594,2.947-4.8C114.8,1311.023,120.775,1295.559,121.8,1291.906Z"
                  transform="translate(-50.195 -689.497)"
                />
                <path
                  style="fill:#1c1c1c;"
                  d="M39.141,1364.214c-6.605-.459-3.9-32.589-3.768-35.674"
                  transform="translate(-18.781 -718.732)"
                />
                <path
                  style="fill:#a57367;"
                  d="M135.432,1341.046c3.8-.629,5.164,6.724-.459,14.1C134.826,1347.692,135.432,1341.046,135.432,1341.046Z"
                  transform="translate(-73.008 -725.477)"
                />
                <path
                  style="fill:#c67a60;"
                  d="M325.382,1176.472s-9.809-.174-3.36-18.085-5.967-25.319-14.537-21.073-2.029,15.184-6.77,17.64,3.833,16.226,3.833,16.226S325.589,1183.4,325.382,1176.472Z"
                  transform="translate(-161.913 -614.649)"
                />
                <path
                  style="fill:#8d2b26;"
                  d="M73.493,1429.068c8.772,1.9,2.3,10.979,9.726,38.4,11.292,41.77,33.37,99.145,33.37,99.145s-28.917,42.146-87.812,44.294C-.283,1585.025-7.779,1442.361,8.332,1436.4c10.052-3.723,24.9-10.158,40.677-11.08C58.516,1424.744,73.493,1429.068,73.493,1429.068Z"
                  transform="translate(-0.003 -771.06)"
                />
                <path
                  style="fill:#8d2b26;"
                  d="M141.545,1311.8s56.536-6.633,65.445-36.991,11.709-62.521,11.709-62.521,25.727-3.213,26.049,18.154-10.286,78.21-35.619,92.26S133.058,1379.985,141.545,1311.8Z"
                  transform="translate(-76.065 -655.747)"
                />
                <path
                  style="fill:#ea5546;"
                  d="M10,1435.979c-11.911,54.3,7.133,47.108,30.946,35.49s11.475-46.139,11.475-46.139S14.688,1427.859,10,1435.979Z"
                  transform="translate(-3.434 -771.095)"
                />
                <path
                  style="fill:#ad431e;"
                  d="M32.285,1435.911c-2.9,43.633.574,38.85,12.889,30.942s9.74-36.8,9.74-36.8S34.319,1429.563,32.285,1435.911Z"
                  transform="translate(-16.947 -773.644)"
                />
                <path
                  style="fill:#591c1b;"
                  d="M162.73,1349.531s46.474-8.634,69.31-44.941c7.8-12.425,19.489-80.569,19.489-80.569s7.978,7.252,5.347,22.647-7.133,60.9-23.868,75.86c-21.95,19.595-40.645,29.252-55.3,32.8C167.517,1357.779,164.066,1355.961,162.73,1349.531Z"
                  transform="translate(-88.037 -662.187)"
                />
                <path
                  style="fill:#a57367;"
                  d="M310.122,1138.71s13.834,9.208,8.225,15.046-5.435,18.714-5.435,18.714-3.525-11.305-4.035-14.362-1.294-2.57-4.719-1.928C306.409,1145.976,303.274,1140.941,310.122,1138.71Z"
                  transform="translate(-164.55 -616.035)"
                />
                <path
                  style="fill:#ffc65a;"
                  d="M1329.47,103.86l13.164,217.913,3.534.776s-2.938-45.175-5.6-95.854C1333.987,101.583,1335.175,108.615,1329.47,103.86Z"
                  transform="translate(-719.236 -56.188)"
                />
                <path
                  style="fill:#ffc65a;"
                  d="M690.914,81.939,175.189,646.517c-3.433.67-1.781,15.57-2.7,10.86,0,0,447.99-486.409,519.907-574.161a.978.978,0,0,0-1.483-1.276Z"
                  transform="translate(-93.317 -44.145)"
                />
                <path
                  style="fill:#262626;"
                  d="M1237.25,0a50.381,50.381,0,1,0,50.381,50.381A50.381,50.381,0,0,0,1237.25,0Zm23.409,21.784a5.986,5.986,0,1,0,.014,0Zm-64.027,25.888a9.4,9.4,0,1,0,.014,0Zm17.181,31.3a5.957,5.957,0,1,1,.014,0Zm0-45.286a5.957,5.957,0,1,1,.014,0ZM1236.7,92.719a9.41,9.41,0,1,1,9.41-9.41,9.41,9.41,0,0,1-9.41,9.41Zm0-65.849a9.41,9.41,0,1,1,9.41-9.41,9.41,9.41,0,0,1-9.41,9.41Zm23.969,52.111a5.967,5.967,0,1,1,5.967-5.967,5.967,5.967,0,0,1-5.967,5.967Zm7.766-21.89a9.414,9.414,0,1,1,9.414-9.414,9.414,9.414,0,0,1-9.414,9.414Z"
                  transform="translate(-642.091)"
                />
                <path
                  style="fill:#ba8a43;"
                  d="M1357,530.29l5.375,95.473S1360.672,537.772,1357,530.29Z"
                  transform="translate(-734.13 -286.884)"
                />
                <path
                  style="fill:#ba8a43;"
                  d="M1369.46,540.036l4.81,92.632V542.244a4.278,4.278,0,0,0-2.171-3.13A1.614,1.614,0,0,0,1369.46,540.036Z"
                  transform="translate(-740.871 -291.448)"
                />
                <path
                  style="fill:#ba8a43;"
                  d="M1387.361,551.06s-2.754,63.7-.693,90.622C1388.646,615.243,1387.361,551.06,1387.361,551.06Z"
                  transform="translate(-749.766 -298.12)"
                />
              </svg>
            </div>
          </div>
          <div class="--comingsoong_build2--">
            <div data-depth="0.4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="b__build"
                width="390"
                height="467.036"
                viewBox="0 0 470.205 467.036"
              >
                <g transform="translate(-0.038 -37.51)">
                  <path
                    style="fill:#c54342;"
                    d="M271.517,0H135.756V67.88H271.517V203.636H0v67.88H339.393V0Z"
                    transform="translate(0.038 54.425)"
                  />
                  <path
                    style="fill:#c67a60;"
                    d="M694.086,239.5s5.531-1.59.862-15.67,1.927-22.123,7.015-19.818,2.028,12.444,4.853,13.785-1.235,14.163-1.235,14.163"
                    transform="translate(-373.793 -109.928)"
                  />
                  <path
                    style="fill:#a57367;"
                    d="M706.845,233.255s1.346-10,1.457-12.628.585-2.346,2.563-2.3c-1.844-8.222-.378-12.905-4.388-13.762,0,0-7.305,9.757-3.793,13.827s4.148,14.882,4.148,14.882"
                    transform="translate(-378.307 -110.434)"
                  />
                  <path
                    style="fill:#0c86a3;"
                    d="M693.846,349.441c-5.72-20.7-2.166-79.057-.318-82.039s11.776-5.07,14.583-2.871,3.945,78.075,3.945,78.075S700.962,375.182,693.846,349.441Z"
                    transform="translate(-372.377 -142.272)"
                  />
                  <path
                    style="fill:#474342;"
                    d="M766.239,1045.427c.576,3.461,38.162,4,40.314,1.844s-5.844-38.471-5.844-38.471l-23.082,1.539S764.086,1032.5,766.239,1045.427Z"
                    transform="translate(-412.96 -544.005)"
                  />
                  <path
                    style="fill:#474342;"
                    d="M982.3,1051.252c2.461-4-9.849-31.082-9.849-31.082L953.37,1021.7s-7.374,25.238,1.231,29.548c4.282,2.139,25.239,4,27.7,0"
                    transform="translate(-512.383 -550.135)"
                  />
                  <path
                    style="fill:#1d4368;"
                    d="M780.553,609.69s-9.218,183.26-8.969,194.76,36.968.922,36.968.922l16.27-129.511,1.6-63.562"
                    transform="translate(-415.964 -328.842)"
                  />
                  <path
                    style="fill:#234f82;"
                    d="M873.36,613.578c.516,6.877,2.355,33.991,6.333,63.82,0,0,24.427,125,29.156,129.377s24.261-5.346,24.261-5.346S930.225,678.283,920.629,612.5"
                    transform="translate(-470.834 -330.357)"
                  />
                  <path
                    style="fill:#c67a60;"
                    d="M760.259,177.447s-6.452.867-6.655-14.606c-.106-8.7-4.839-11.306-8.43-13.532-2.793-1.733-5.6-.263-7.586,1.659-4.558,4.4-.922,7.181,1.212,11.227,1.383,2.622,6.508,13.02,6.508,13.02"
                    transform="translate(-396.458 -80.233)"
                  />
                  <path
                    style="fill:#a57367;opacity: 0.6;"
                    d="M753.147,177.081s-4.568-8.089-5.531-10.31-1.323-1.8-3.139-1.028c-1.009-7.761-3.941-11.227-.516-13.463,0,0,10.006,5.655,8.107,10.462s1.1,14.338,1.1,14.338"
                    transform="translate(-400.204 -82.249)"
                  />
                  <path
                    style="fill:#0c86a3;"
                    d="M748.066,199.4,767.041,245.7l37.549,1.263-39.973-55.04S745.416,192.939,748.066,199.4Z"
                    transform="translate(-403.152 -103.62)"
                  />
                  <path
                    style="fill:#c67a60;"
                    d="M834.695,284.844l-4.424,1.65,4.558,15.8,38.13,5.927,2.129-22.031L863.846,283l-24.8.972A14.213,14.213,0,0,0,834.695,284.844Z"
                    transform="translate(-447.604 -152.721)"
                  />
                  <path
                    style="fill:#c67a60;"
                    d="M845.07,145.59c-12.578.4-27.866-6.913-25.6,12.416s1.876,33.129,5.913,37.572c11.6,6.563,12.076,12.564,29.986,5.581C871,166.045,849.817,145.443,845.07,145.59Z"
                    transform="translate(-441.66 -78.086)"
                  />
                  <path
                    style="fill:#a57367;"
                    d="M828.65,247.37a13.8,13.8,0,0,1,11.688,9.186C848.519,261.4,829.217,254.348,828.65,247.37Z"
                    transform="translate(-446.731 -133.513)"
                  />
                  <path
                    style="fill:#c67a60;"
                    d="M865.92,276.777a2.828,2.828,0,0,1-.733,2.913c-5.849,7.808-13.735,11.324-23.326,10.329-9.37-.972-27.041-6.858-26.658-7.775,3.931-5.669,16.025-10.992,17.883-16.947,5.121,1.641,10.218,0,15.32-.134,2.618-.069,2.95-3.139,4.641-.53C854.379,270.27,860.274,276.058,865.92,276.777Z"
                    transform="translate(-439.478 -142.254)"
                  />
                  <path
                    style="fill:#1c1c1c;"
                    d="M820.966,161c1.019.143,4.572,9.448,6.4,9.416,5.715-.1,11.619,15.09,17.514,13.274,7.476-2.3,4.369-7.766,12.73-7.434,1.194.046,9.172-26.271,8.9-29.548s1.383-22.625-1.917-26.322c-3.443-3.881-24.856-15.85-43.421-3.065a4.644,4.644,0,0,0,.341,4.3C824.875,128.473,813.62,159.108,820.966,161Z"
                    transform="translate(-441.218 -60.304)"
                  />
                  <path
                    style="fill:#262626;"
                    d="M803.485,144.066c-2.434-8.679,5.844-14.329,9.43-20.026a32.8,32.8,0,0,0,.88,3.2c2.765,7.337,8.2,11.873,14.527,15.408-3.314.516-6.637-.461-9.859-1.6-2.185-.774-2.8.037-2.945,1.927-.253,3.226-.834,6.453-2.6,8.886a7.168,7.168,0,0,0-1.1,5.909c.539,3.226.857,6.489,1.346,10.338-2.337-1.659-1.553-3.6-2.765-4.7C810.4,163.4,804.522,147.8,803.485,144.066Z"
                    transform="translate(-432.927 -67.025)"
                  />
                  <path
                    style="fill:#a57367;"
                    d="M819.343,195.127c-3.272-.166-4.06,7.8,1.1,14.873C820.2,202.087,819.343,195.127,819.343,195.127Z"
                    transform="translate(-440.305 -105.347)"
                  />
                  <path
                    style="fill:#00a8db;"
                    d="M834.42,294.55c8.573,3.867,15.269,3.8,20.017,11.9,6.107,10.412-6.158,83.136-5.125,87.57-1.982,33.018,4.89,45.882,5.627,51.039-21.75,7.734-42.8,8.651-85.537,1.885-9.582-1.516-8.162-93.81-9.739-105.171-2.885-20.86-11.734-20-11.283-23.123,1.092-6.628,3.973-11.707,11.269-14.394,7.1-2.613,13.79-6.208,20.662-9.375,2,.631,2.544,2.443,3.54,3.867,3.775,5.393,8.3,10.075,14.947,12.274,12.481,4.125,25.543,1.489,32.645-11.757C832.309,297.629,832.54,295.615,834.42,294.55Z"
                    transform="translate(-403.448 -158.948)"
                  />
                  <path
                    style="fill:#00a8db;"
                    d="M820.56,288.9c.567-.392,1.152-.76,1.7-1.175,2.24-1.691,20.855,3.484,39.088-2.9,3.48.355,5.6,1.129,9.586,3.226.507.267.977.613,1.461.922-2.636,9.264-7.775,16.592-17.38,19.169s-18.606.747-26.16-6.273a36.738,36.738,0,0,1-7.692-9.826C820.633,291.074,819.638,290.162,820.56,288.9Z"
                    transform="translate(-442.167 -153.703)"
                  />
                  <path
                    style="fill:#065c70;"
                    d="M819.237,293.665c6.545,7.374,28.114,8.831,43.107,4.277,4.475-1.36,5.664-1.627,7.964-3.618.244-.212.512-.419.765-.627l.765.134c-3.263,3.765-8.185,5.761-18.32,6.789-9.711.986-18.556,1.41-26.2-1.383a36.644,36.644,0,0,1-9.591-5.259,3.014,3.014,0,0,1,1.507-.313Z"
                    transform="translate(-440.844 -158.469)"
                  />
                  <path
                    style="fill:#00a8db;"
                    d="M722.174,341.409s-22.584,38.074-22,41.545-5.448,26.557,14.44,19.086,35.364-41.637,32.723-49.168S741.854,328.785,722.174,341.409Z"
                    transform="translate(-377.241 -181.73)"
                  />
                  <path
                    style="fill:#684842;"
                    d="M749.818,102.5s-14.555,30.622-16.859,39.7c-.419,1.65,3.014,2.94,3.89,1.235,4.673-9.144,16.067-39.72,16.067-39.72Z"
                    transform="translate(-395.124 -55.413)"
                  />
                  <path
                    style="fill:#1c1c1c;"
                    d="M752.54,82.273a3.716,3.716,0,0,0-2.466,4.609c3.074,2.94,17.348,11.753,17.348,11.753s3.871-1.115,3.871-2.438S756.817,81.72,756.817,81.72Z"
                    transform="translate(-404.275 -44.21)"
                  />
                  <path
                    style="fill:#262626;"
                    d="M755.63,82.295l14.315,13.615.567,2.765s3.95-1.037,3.872-2.438S759.907,81.76,759.907,81.76Z"
                    transform="translate(-407.365 -44.232)"
                  />
                  <path
                    style="fill:#684842;"
                    d="M772,94.353c-.055.6,2.5,2.705,3.125,2.59s1.323-.392,1.166-1.065-2.8-2.351-3.226-2.245a3.066,3.066,0,0,0-1.065.719Z"
                    transform="translate(-416.19 -50.631)"
                  />
                  <path
                    style="fill:#a57367;"
                    d="M733.8,157.324h0c1.585.968,2.512,1.756,3.729,0s4-7.835,3.867-9.37a1.465,1.465,0,0,0-.028-.2,3,3,0,0,0-4.8-1.8c-2.586,1.986-5.858,5.07-5.148,7.434C732.526,156.978,733.665,157.3,733.8,157.324Z"
                    transform="translate(-394.263 -78.494)"
                  />
                  <path
                    style="fill:#c67a60;"
                    d="M753.136,153.076s-10.6-7.374-10.536-4.8,4.07,11.753,10.462,10.1S753.136,153.076,753.136,153.076Z"
                    transform="translate(-400.341 -79.796)"
                  />
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5 comingsoon__info">
        <div class="comingsoon__box">
          <div class="comingsoon__title">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="500.949"
              height="283.79"
              viewBox="0 0 568.949 283.79"
            >
              <g transform="translate(-1227 -143)">
                <line
                  style="fill: none;
                  stroke: #212121;
                  stroke-linecap: round;
                  stroke-width: 5px;"
                  x2="160.804"
                  transform="translate(1229.5 324.915)"
                />
                <line
                  style="fill: none;
                  stroke: #212121;
                  stroke-linecap: round;
                  stroke-width: 5px;"
                  x2="201.229"
                  transform="translate(1349.878 341.983)"
                />
                <text
                  style="font-size: 64px;
                  font-family: Montserrat-Bold, Montserrat;
                  font-weight: 700;fill: #212121;"
                  transform="translate(1229.949 205)"
                >
                  <tspan x="0" y="0">WORK</tspan>
                  <tspan x="0" y="78">IN PROGRESS</tspan>
                </text>
                <text
                  style="fill: #212121;font-size: 50px;
                  font-family: Montserrat-Light, Montserrat;
                  font-weight: 300;"
                  transform="translate(1224.949 413.79)"
                >
                  <tspan x="0" y="0">We will be online soon!</tspan>
                </text>
              </g>
            </svg>
          </div>
          <div class="comingsoon__text">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="500"
              height="170"
              style="transform: translate(5px, 10px);"
              viewBox="0 0 562 170"
            >
              <text
                style="fill: #212121;
                  font-size: 28px;
                  font-family: Montserrat-Regular, Montserrat;"
                transform="translate(0 27)"
              >
                <tspan x="0" y="0">
                  We are in the process of making this 
                </tspan>
                <tspan x="0" y="34">website and it will be online soon.</tspan>
                <tspan x="0" y="68"></tspan>
                <tspan x="0" y="102"></tspan>
                <tspan x="0" y="136">In the meantime…</tspan>
              </text>
            </svg>
          </div>
          <div class="comingsoon__btn">
            <a
              class="btn-comingsoon"
              href="https://www.youtube.com/watch?v=1HygThMLzGs"
              target="blank"
              >A nice video</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Background from "@/components/shared/background.vue";
export default {
  name: "Home",
  components: {
    Background,
  },
  mounted() {
    new this.$parallax(document.querySelector(".--comingsoong_build1--"));
    new this.$parallax(document.querySelector(".--comingsoong_build2--"));
  },
};
</script>

<style lang="scss" scoped>
#seyvom__comingsoon {
  padding-top: 2em;
  padding-left: 4em;
  padding-right: 4em;
  height: 100%;
  width: 100%;
}
.v__build {
  position: absolute;
  transform: translate(50px, -100px);
}
.b__build {
  position: relative;
  transform: translate(520px, 390px);
}
.comingsoon__btn {
  position: absolute;
}
.btn-comingsoon {
  color: #212121 !important;
  border-radius: 7px;
  padding: 10px;
  width: 130px;
  text-align: center;
  font-size: 16px;
  font-family: Poppins-Bold, Poppins;
  font-weight: 700;
  box-shadow: 5px 10px;
  background-color: #ffce00;
  -webkit-box-shadow: 3px 6px 13px -3px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 3px 6px 13px -3px rgba(0, 0, 0, 0.36);
  box-shadow: 3px 6px 13px -3px rgba(0, 0, 0, 0.36);
  float: right;
  transform: translate(305px, -40px);
  text-decoration: unset;
}
.btn-comingsoon:hover {
  background-color: #e6bf00;
}
.comingsoon__build_box {
  position: absolute;
  float: right;
}
.comingsoon__box {
  padding-top: 14em;
}
@media only screen and (max-width: 1700px) {
  .v__build {
    transform: scale(0.9) translate(0px, -160px);
  }
  .b__build {
    transform: scale(0.9) translate(490px, 360px);
  }
  .comingsoon__box {
    padding-top: 12.5em;
    transform: scale(0.85) translate(50px, 0px);
  }
}
@media only screen and (max-width: 1400px) {
  #seyvom__comingsoon {
    padding-left: 0em;
  }
  .v__build {
    transform: scale(0.8) translate(0px, -235px);
  }
  .b__build {
    transform: scale(0.8) translate(515px, 325px);
  }
  .comingsoon__box {
    padding-top: 10em;
    transform: scale(0.75) translate(50px, 0px);
  }
  .btn-comingsoon {
    padding: 12px;
    width: 140px;
    font-size: 18px;
    transform: translate(305px, -44px);
  }
}
@media only screen and (min-width: 1400px) and (max-height: 810px) {
  #seyvom__comingsoon {
    padding-left: 0em;
  }
  .v__build {
    transform: scale(0.8) translate(0px, -235px);
  }
  .b__build {
    transform: scale(0.8) translate(515px, 325px);
  }
  .comingsoon__box {
    padding-top: 10em;
    transform: scale(0.75) translate(50px, 0px);
  }
  .btn-comingsoon {
    padding: 12px;
    width: 140px;
    font-size: 18px;
    transform: translate(305px, -44px);
  }
}
@media only screen and (max-width: 1230px) {
  .v__build {
    transform: scale(0.75) translate(0px, -280px);
  }
  .b__build {
    transform: scale(0.75) translate(530px, 300px);
  }
  .comingsoon__build_box {
    transform: translate(-30px, 0px);
  }
  .comingsoon__box {
    padding-top: 9em;
    transform: scale(0.65) translate(50px, 0px);
  }
  .btn-comingsoon {
    padding: 14px;
    width: 150px;
    font-size: 19px;
        transform: translate(305px,-46px);
  }
}
@media only screen and (max-width: 1100px) {
  .v__build {
    transform: scale(0.65) translate(0px, -390px);
  }
  .b__build {
    transform: scale(0.65) translate(570px, 250px);
  }
  .comingsoon__build_box {
    transform: translate(-80px, 0px);
  }
  .comingsoon__box {
    padding-top: 6em;
    transform: scale(0.55) translate(50px, 0px);
  }
  .btn-comingsoon {
    padding: 15px;
    width: 160px;
    font-size: 20px;
    transform: translate(305px, -50px);
  }
}
@media only screen and (max-width: 950px) {
  .comingsoon__info {
    max-width: 100%;
  }
  .v__build {
    transform: scale(0.55) translate(0px, -530px);
  }
  .b__build {
    transform: scale(0.55) translate(620px, 190px);
  }
  .comingsoon__build_box {
    transform: translate(-50px, 0px);
    position: relative;
  }
  #seyvom__comingsoon {
    padding-top: 2em;
    padding-left: 0em;
    padding-right: 4em;
  }
  .comingsoon__box {
    position: absolute;
    padding-top: 0em;
    transform: scale(0.65) translate(-420px, 420px);
  }
  .btn-comingsoon {
    padding: 13px;
    width: 150px;
    font-size: 19px;
    transform: translate(305px, -44px);
  }
}
@media only screen and (max-width: 768px) {
  .comingsoon__info {
    max-width: fit-content;
  }
  .comingsoon__we {
    max-width: 60%;
  }
}
@media only screen and (max-width: 660px) {
  .comingsoon__we {
    max-width: 63%;
  }
}
@media only screen and (max-width: 570px) {
  #seyvom__comingsoon {
    padding-right: 3em;
  }
}
@media only screen and (max-width: 525px) {
  #seyvom__comingsoon {
    padding-right: 2em;
  }
}
@media only screen and (max-width: 499px) {
  .comingsoon__info {
    max-width: 100%;
  }
  .v__build {
    transform: scale(0.4) translate(100px, -890px);
  }
  .b__build {
    transform: scale(0.4) translate(860px, 20px);
  }
  .comingsoon__box {
    transform: scale(0.6) translate(-65px, -400px);
  }
  #seyvom__comingsoon {
    padding-right: 4em;
  }
  .btn-comingsoon {
    padding: 14px;
    width: 160px;
    font-size: 20px;
    transform: translate(305px, -46px);
  }
}
@media only screen and (max-width: 399px) and (max-height: 738px) {
  .v__build {
    transform: scale(0.34) translate(120px, -1140px);
  }
  .b__build {
    transform: scale(0.34) translate(950px, -105px);
  }
  .comingsoon__box {
    max-width: 100%;
    transform: scale(0.54) translate(-40px, -570px);
  }
}
@media only screen and (max-width: 350px) and (max-height: 738px) {
  .v__build {
    transform: scale(0.3) translate(180px, -1360px);
  }
  .b__build {
    transform: scale(0.3) translate(1080px, -215px);
  }
  .comingsoon__box {
    transform: scale(0.46) translate(-60px, -790px);
  }
}
</style>
